import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = `Resource List for Starting a DPC Practice`;
    const desc = `Use these resources to learn more about how to start a direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="learn">
        <Head title={title} description={desc} url="/todo/learn/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
